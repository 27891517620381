<template>
  <v-dialog v-model="mostrar" persistent :width="encuesta ? '900' : '450'">
    <v-card :loading="loading">
      <v-toolbar color="primary" dense dark flat
        >{{ encuesta ? "Modificar " : "Crear " }}encuesta</v-toolbar
      >
      <v-card-text class="mt-3 px-4 pb-0">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="nombre"
              label="Nombre"
              outlined
              dense
              :disabled="loading"
              :error-messages="errors.nombre"
              @input="$v.nombre.$touch()"
              @blur="$v.nombre.$touch()"
              @keyup.enter="!encuesta && submit()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="encuesta">
            <span class="text-subtitle-1 text--secondary"
              >Descripción de la encuesta:</span
            >
            <editor-texto
              ref="editorTexto"
              :texto="descripcion"
              contenido="encuesta"
              :idContenido="encuesta._id"
            ></editor-texto>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          small
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :disabled="!esValido || loading"
          @click="submit()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import editorTexto from "@/components/globals/template/contenidos/editorTexto";

import { crearEncuesta, modificarEncuesta } from "./encuestas.service";

export default {
  mixins: [validationMixin],

  components: {
    "editor-texto": editorTexto,
  },

  props: {
    mostrar: { type: Boolean, default: false },
    areaEncuesta: { type: String, required: false },
    encuesta: { type: Object, default: () => null },
  },

  computed: {
    ...mapGetters(["apps", "appName"]),
    errors() {
      const errors = {
        nombre: [],
        descripcion: [],
      };

      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.nombre.push("Campo requerido.");

      return errors;
    },

    esValido() {
      if (this.errors.nombre.length) return false;
      if (this.errors.descripcion.length) return false;
      return true;
    },
  },

  validations: {
    nombre: { required },
  },

  data: () => ({
    loading: false,
    nombre: "",
    descripcion: "",
  }),

  watch: {
    encuesta() {
      if (this.encuesta) this.setEncuestaData();
    },
  },

  created() {
    if (this.encuesta) this.setEncuestaData();
  },

  methods: {
    setEncuestaData() {
      this.nombre = this.encuesta.nombre;
      this.descripcion = this.encuesta.descripcion;
    },

    async submit() {
      this.$v.nombre.$touch();
      if (!this.esValido) return;

      this.loading = true;
      try {
        const data = {
          nombre: this.nombre,
          ...(this.encuesta && {
            descripcion: this.$refs.editorTexto.obtenerTexto(),
          }),
          ...(!this.encuesta && { areaEncuesta: this.areaEncuesta }),
        };

        const serverResponse = this.encuesta
          ? await modificarEncuesta(this.encuesta._id, data)
          : await crearEncuesta(data);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("nuevoLog", serverResponse.nuevoLog);
          this.$emit("encuestaGuardada", serverResponse.encuesta);
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
