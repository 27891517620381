import {
  getServerRequest,
  postServerRequest,
  putServerRequest,
  deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "../../../../store/store";

const httpURL = store.getters.httpURL;

export const getAreasEncuesta = async () => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/areas/listado`;
  return await getServerRequest(url, config);
};

export const crearAreaEncuestas = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/area`;

  return await postServerRequest(url, data, config);
};

export const modificarAreaEncuestas = async (idArea, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/area/${idArea}`;

  return await putServerRequest(url, data, config);
};

export const getEncuestasByArea = async (idAreaEncuesta) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/${idAreaEncuesta}`;
  return await getServerRequest(url, config);
};

export const getEncuestasCompartidas = async () => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/compartidas`;
  return await getServerRequest(url, config);
};

export const crearEncuesta = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas`;

  return await postServerRequest(url, data, config);
};

export const modificarEncuesta = async (idEncuesta, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuesta/${idEncuesta}`;

  return await putServerRequest(url, data, config);
};

export const clonarEncuesta = async (idEncuesta, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuesta/${idEncuesta}/clon`;

  return await putServerRequest(url, data, config);
};

export const getEncuesta = async (idEncuesta) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuesta/${idEncuesta}`;
  return await getServerRequest(url, config);
};

export const crearSeccionEncuesta = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/seccion`;

  return await postServerRequest(url, data, config);
};

export const getSeccionEncuesta = async (idEncuesta, idSeccion) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/${idEncuesta}/seccion/${idSeccion}`;
  return await getServerRequest(url, config);
};

export const modificarSeccionEncuesta = async (idSeccion, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/seccion/${idSeccion}`;

  return await putServerRequest(url, data, config);
};

export const modificarOrdenSeccionEncuesta = async (
  idEncuesta,
  idSeccion,
  data
) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/${idEncuesta}/seccion/${idSeccion}/orden`;

  return await putServerRequest(url, data, config);
};

export const crearReactivoEncuesta = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/pregunta`;

  return await postServerRequest(url, data, config);
};

export const modificarReactivoEncuesta = async (idReactivo, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/pregunta/${idReactivo}`;

  return await putServerRequest(url, data, config);
};

export const modificarOpcionReactivoEncuesta = async ({
  idReactivo,
  idOpcion,
  data,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/pregunta/${idReactivo}/opcion/${idOpcion}`;

  return await putServerRequest(url, data, config);
};

export const agregarOpcionReactivoEncuesta = async (idReactivo, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/pregunta/${idReactivo}/opcion`;

  return await postServerRequest(url, data, config);
};

export const removerOpcionReactivoEncuesta = async (idReactivo, idOpcion) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/pregunta/${idReactivo}/opcion/${idOpcion}`;

  return await deleteServerRequest(url, config);
};

export const modificarNivelReactivoEncuesta = async ({
  idReactivo,
  idNivel,
  data,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/pregunta/${idReactivo}/nivel/${idNivel}`;

  return await putServerRequest(url, data, config);
};

export const agregarNivelReactivoEncuesta = async (idReactivo, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/pregunta/${idReactivo}/nivel`;

  return await postServerRequest(url, data, config);
};

export const removerNivelReactivoEncuesta = async (idReactivo, idNivel) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/pregunta/${idReactivo}/nivel/${idNivel}`;

  return await deleteServerRequest(url, config);
};

export async function obtenerUsuariosService(tipo, idEncuesta) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/usuarios/${tipo}/${idEncuesta}`;
  return await getServerRequest(url, config);
}

export async function agregarUsuarioService(tipo, idPlantilla, idUsuario) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const data = { idUsuario };
  const url = `${httpURL}/encuestas/usuarios/${tipo}/${idPlantilla}`;
  return await putServerRequest(url, data, config);
}

export async function quitarUsuarioService(ruta, data) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/${ruta}`;
  return await patchServerRequest(url, data, config);
}

export const quitarEncuestaCompartida = async (idEncuesta) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/usuario/${idEncuesta}`;
  return await deleteServerRequest(url, config);
};

export const eliminarEncuesta = async (idEncuesta) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/encuestas/${idEncuesta}`;
  return await deleteServerRequest(url, config);
};
