<template>
  <v-container>
    <v-skeleton-loader
      v-if="!escuelaTienePermiso"
      type="list-item-two-line@5"
    ></v-skeleton-loader>
    <v-card v-else>
      <v-tabs
        v-if="app === 'campus'"
        v-model="tab"
        centered
        icons-and-text
        background-color="secondary"
        dark
      >
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab href="#listado" to="#listado">
          Encuestas
          <v-icon>mdi-clipboard-list-outline</v-icon>
        </v-tab>
        <v-tab href="#compartidos" to="#compartido">
          Compartido
          <v-icon>mdi-account-switch</v-icon>
        </v-tab>
      </v-tabs>

      <v-toolbar v-else dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer>
        <v-icon class="mr-2">mdi-clipboard-list-outline</v-icon>
        <span>Encuestas</span>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item value="listado">
          <v-container>
            <listado-encuestas></listado-encuestas>
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="app === 'campus'" value="compartido">
          <encuestas-compartidas></encuestas-compartidas>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import listadoEncuestas from "./listadoEncuestas.vue";
import encuestasCompartidas from "./encuestasCompartidas.vue";

export default {
  components: {
    "listado-encuestas": listadoEncuestas,
    "encuestas-compartidas": encuestasCompartidas,
  },

  data: () => ({
    tab: "listado",
    escuelaTienePermiso: false,
  }),
  computed: {
    ...mapGetters(["app", "menu", "sessionToken"]),
  },
  watch: {
    sessionToken(value) {
      if (value) {
        if (!this.$route.hash && this.app === "campus") {
          this.escuelaTienePermiso = true;
          this.$router.push("#listado");
        }
        if (this.app === "escuela") this.validarPermisoRuta();
        this.escuelaTienePermiso = true;
      }
    },
  },

  mounted() {
    if (!this.$route.hash && this.app === "campus") {
      this.escuelaTienePermiso = true;
      this.$router.push("#listado");
    }
    if (this.app === "escuela" && this.sessionToken && this.menu.length > 0) {
      this.validarPermisoRuta();
    }
  },

  methods: {
    validarPermisoRuta() {
      const menuEvaluacion = this.menu.find((x) => x.title === "Evaluación");
      if (menuEvaluacion) {
        const itemIndex = menuEvaluacion.items.findIndex(
          (x) => x.title === "Encuestas"
        );
        console.log(itemIndex);
        if (itemIndex == -1) {
          this.$router.push("/dashboard");
        } else this.escuelaTienePermiso = true;
      }
    },
  },
};
</script>
