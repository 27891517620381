<template>
  <global-mensajeDialog
    :mostrar="mostrar"
    title="Seleccionar área"
    :noClose="true"
    :loading="loading"
  >
    <v-container slot="content">
      <v-row dense>
        <v-col cols="12">
          <v-select
            v-model="areaSeleccionada"
            :items="areasEncuesta"
            item-value="_id"
            item-text="nombre"
            label="Área"
            outlined
            dense
            :disabled="loading"
            no-data-text="No hay áreas de encuesta disponibles"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-container>

    <template slot="footer">
      <v-spacer></v-spacer>
      <global-secondaryButton
        texto="Cancelar"
        :disabled="loading"
        @clicked="$emit('cancelar')"
      />
      <global-primaryButton
        texto="Guardar"
        :loading="loading"
        class="rightBtn-alert"
        @clicked="submit()"
      />
    </template>
  </global-mensajeDialog>
</template>

<script>
import { modificarEncuesta } from "./encuestas.service";

export default {
  props: {
    mostrar: { type: Boolean, default: false },
    areasEncuesta: { type: Array, default: () => [] },
    encuesta: { type: Object, default: () => null },
  },

  data: () => ({
    loading: false,
    areaSeleccionada: "",
  }),

  watch: {
    areasEncuesta() {
      this.setAreaSeleccionada();
    },
  },

  mounted() {
    this.setAreaSeleccionada();
  },

  methods: {
    setAreaSeleccionada() {
      if (this.encuesta && this.areasEncuesta.length) {
        this.areaSeleccionada = this.areasEncuesta.filter(
          (e) => e._id === this.encuesta.areaEncuesta
        )[0]._id;
      }
    },

    async submit() {
      this.loading = true;

      try {
        const data = {
          areaEncuesta: this.areaSeleccionada,
          nombre: this.encuesta.nombre,
        };

        const serverResponse = await modificarEncuesta(this.encuesta._id, data);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("encuestaMovida", serverResponse.encuesta);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
