<template>
  <div>
    <v-container>
      <v-card flat>
        <v-card-title>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="mdi-magnify"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-card-text v-if="!encuestas.length && !loading"
            >Sin encuestas compartidas.</v-card-text
          >
          <loading-encuestas v-else-if="loading" />
          <v-list v-else>
            <v-list-item
              v-for="encuesta in encuestasFiltradas"
              :key="encuesta._id"
            >
              <v-list-item-avatar>
                <v-avatar color="primary">
                  <v-icon dark>mdi-clipboard-list-outline</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="`encuestas/${encuesta._id}`" class="link"
                    >{{ encuesta.nombre }}
                  </router-link>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span>
                    Creada: {{ getLocaleString(encuesta.creador.fecha) }}
                  </span>
                  <v-chip x-small class="ml-1">{{
                    getRolEncuesta(encuesta)
                  }}</v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(menuEncuestaItem, index) in menuEncuesta"
                      :key="index"
                      @click="
                        menuAction({ ...menuEncuestaItem, id: encuesta._id })
                      "
                    >
                      <v-list-item-title>
                        <v-icon small class="mr-2">{{
                          menuEncuestaItem.icon
                        }}</v-icon>
                        <span>{{ menuEncuestaItem.text }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>

    <clonar-encuesta-dialog
      v-if="mostrarClonarEncuesta"
      :mostrar="mostrarClonarEncuesta"
      :areas="areas"
      :encuesta="encuestaParaModificar"
      @cancelar="cerrarClonarEncuesta"
      @encuestaClonada="encuestaClonada"
    />

    <quitar-encuesta-dialog
      v-if="mostrarQuitarEncuesta"
      :mostrar="mostrarQuitarEncuesta"
      :encuesta="encuestaParaModificar"
      @cancelar="cerrarQuitarEncuesta"
      @encuestaEliminada="encuestaEliminada"
    ></quitar-encuesta-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { getEncuestasCompartidas, getAreasEncuesta } from "./encuestas.service";

import loadingEncuestas from "./loadingEncuestas.vue";

export default {
  components: {
    "loading-encuestas": loadingEncuestas,
    "clonar-encuesta-dialog": () => import("./clonarEncuestaDialog.vue"),
    "quitar-encuesta-dialog": () =>
      import("./quitarEncuestaCompartidaDialog.vue"),
  },

  data: () => ({
    loading: true,
    search: "",
    encuestas: [],
    encuestaParaModificar: null,
    mostrarClonarEncuesta: false,
    mostrarQuitarEncuesta: false,
    menuEncuesta: [
      {
        text: "Copiar a mis recursos",
        action: "clonarEncuesta",
        icon: "mdi-content-copy",
      },
      {
        text: "Quitar de compartidos",
        action: "quitarEncuesta",
        icon: "mdi-delete",
      },
    ],
    areas: [],
  }),

  computed: {
    ...mapGetters(["sessionToken", "userId"]),

    encuestasFiltradas() {
      if (this.search)
        return this.encuestas.filter((e) =>
          e.nombre.toUpperCase().includes(this.search.toUpperCase())
        );
      return this.encuestas;
    },
  },

  watch: {
    async sessionToken(value) {
      if (value) {
        await this.getEncuestas();
        await this.getAreasEncuesta();
      }
    },
  },

  async mounted() {
    if (this.sessionToken) {
      await this.getEncuestas();
      await this.getAreasEncuesta();
    }
  },

  methods: {
    menuAction(data) {
      switch (data.action) {
        case "clonarEncuesta":
          this.encuestaParaModificar = this.encuestas.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarClonarEncuesta = true;
          break;
        case "quitarEncuesta":
          this.encuestaParaModificar = this.encuestas.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarQuitarEncuesta = true;
          break;
      }
    },

    getLocaleString(fecha) {
      const date = DateTime.fromISO(fecha)
        .setLocale("es")
        .toLocaleString(DateTime.DATE_FULL);
      return date;
    },

    async getEncuestas() {
      this.loading = true;

      try {
        const serverResponse = await getEncuestasCompartidas();
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);

        this.encuestas = serverResponse.encuestas;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async getAreasEncuesta() {
      this.loading = true;

      try {
        const serverResponse = await getAreasEncuesta();
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);

        this.areas = serverResponse.areasEncuesta;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    getRolEncuesta(encuesta) {
      const esColaborador = encuesta.colaboradores.find(
        (e) => e == this.userId
      );

      return esColaborador ? "Colaborador" : "Compartido";
    },

    cerrarClonarEncuesta() {
      this.mostrarClonarEncuesta = false;
      this.encuestaParaModificar = null;
    },

    encuestaClonada(encuesta) {
      this.mostrarClonarEncuesta = false;
      this.$emit("encuestaClonada", encuesta);
    },

    cerrarQuitarEncuesta() {
      this.mostrarQuitarEncuesta = false;
      this.encuestaParaModificar = null;
    },

    encuestaEliminada(encuesta) {
      this.cerrarQuitarEncuesta();
      this.encuestas = this.encuestas.filter((e) => e._id != encuesta._id);
    },
  },
};
</script>
