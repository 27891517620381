import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":_vm.encuesta ? '900' : '450'},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{attrs:{"color":"primary","dense":"","dark":"","flat":""}},[_vm._v(_vm._s(_vm.encuesta ? "Modificar " : "Crear ")+"encuesta")]),_c(VCardText,{staticClass:"mt-3 px-4 pb-0"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Nombre","outlined":"","dense":"","disabled":_vm.loading,"error-messages":_vm.errors.nombre},on:{"input":function($event){return _vm.$v.nombre.$touch()},"blur":function($event){return _vm.$v.nombre.$touch()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }!_vm.encuesta && _vm.submit()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1),(_vm.encuesta)?_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 text--secondary"},[_vm._v("Descripción de la encuesta:")]),_c('editor-texto',{ref:"editorTexto",attrs:{"texto":_vm.descripcion,"contenido":"encuesta","idContenido":_vm.encuesta._id}})],1):_vm._e()],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"secondary","small":"","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","small":"","disabled":!_vm.esValido || _vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Guardar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }