<template>
  <v-dialog v-model="mostrar" persistent width="450">
    <v-card :loading="loading">
      <v-toolbar dense color="primary" dark flat>
        <v-toolbar-title
          >{{ area ? "Modificar " : "Crear " }}área</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="mt-3 px-4 pb-0">
        <v-text-field
          v-model="nombre"
          label="Nombre"
          outlined
          dense
          :disabled="loading"
          :error-messages="errors.nombre"
          @input="$v.nombre.$touch()"
          @blur="$v.nombre.$touch()"
          @keyup.enter="submit()"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          small
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :disabled="!esValido || loading"
          @click="submit()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  crearAreaEncuestas,
  modificarAreaEncuestas,
} from "./encuestas.service";

export default {
  mixins: [validationMixin],

  props: {
    mostrar: { type: Boolean, default: false },
    area: { type: Object, default: () => null },
  },

  computed: {
    errors() {
      const errors = {
        nombre: [],
      };

      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.nombre.push("Campo requerido.");

      return errors;
    },

    esValido() {
      if (this.errors.nombre.length) return false;
      return true;
    },
  },

  validations: {
    nombre: { required },
  },

  data: () => ({
    loading: false,
    nombre: "",
  }),

  watch: {
    area() {
      this.setAreaData();
    },
  },

  mounted() {
    if (this.area) {
      this.setAreaData();
    }
  },

  methods: {
    setAreaData() {
      if (this.area) {
        this.nombre = this.area.nombre;
      }
    },

    async submit() {
      this.$v.nombre.$touch();
      if (!this.esValido) return;

      this.loading = true;
      try {
        const data = { nombre: this.nombre, tipo: this.tipoArea };
        if (this.area) delete data.tipo;
        const serverResponse = this.area
          ? await modificarAreaEncuestas(this.area._id, data)
          : await crearAreaEncuestas(data);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("areaGuardada", serverResponse.area);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
