<template>
  <div>
    <v-container>
      <v-card flat>
        <v-card-title class="d-flex align-start pb-0">
          <v-row>
            <v-col md="6" sm="12">
              <v-container>
                <selector-areas
                  :areas="areasEncuesta"
                  :menu="esAdmin"
                  v-model="areaSeleccionada"
                  @change="getEncuestas"
                  @agregarArea="agregarArea"
                  @modificarArea="modificarArea"
                />
              </v-container>
            </v-col>
            <v-col md="6" sm="12">
              <v-container>
                <div class="d-flex">
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    append-icon="mdi-magnify"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                  <v-tooltip v-if="esAdmin" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        fab
                        small
                        style="margin-left: 10px"
                        :disabled="!areasEncuesta.length || !areaSeleccionada"
                        @click="abrirCreacionEncuesta"
                        ><v-icon>mdi-text-box-check</v-icon></v-btn
                      >
                    </template>
                    <span>Agregar encuesta</span>
                  </v-tooltip>
                </div>
              </v-container>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!encuestas.length && !loading"
          >No se han generado encuestas.</v-card-text
        >
        <loadingEncuestas v-else-if="loading" />
        <v-list v-else>
          <v-list-item
            v-for="encuesta in encuestasFiltradas"
            :key="encuesta._id"
          >
            <v-list-item-avatar>
              <v-avatar color="primary">
                <v-icon dark>mdi-clipboard-list-outline</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <router-link :to="`encuestas/${encuesta._id}`" class="link"
                  >{{ encuesta.nombre }}
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle
                >Creada:
                {{
                  getLocaleString(encuesta.creador.fecha)
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action v-if="esAdmin">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(menuEncuestaItem, index) in menuEncuesta"
                    :key="index"
                    @click="
                      menuAction({ ...menuEncuestaItem, id: encuesta._id })
                    "
                  >
                    <v-list-item-title>
                      <v-icon small class="mr-2">{{
                        menuEncuestaItem.icon
                      }}</v-icon>
                      <span>{{ menuEncuestaItem.text }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="app === 'campus'"
                    @click="mostrarEliminarEncuesta(encuesta)"
                  >
                    <v-list-item-title>
                      <v-icon small class="mr-2">mdi-delete</v-icon>
                      <span>Eliminar encuesta</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>

    <crearAreaEncuestaDialog
      v-if="mostrarCreacionArea"
      :mostrar="mostrarCreacionArea"
      :area="areaParaModificar"
      @cancelar="cerrarCreacionArea"
      @areaGuardada="areaCreada"
    />

    <crearEncuestaDialog
      v-if="mostrarCreacionEncuesta"
      :mostrar="mostrarCreacionEncuesta"
      :areaEncuesta="areaSeleccionada"
      :encuesta="encuestaParaModificar"
      @cancelar="cerrarCreacionEncuesta"
      @encuestaGuardada="encuestaGuardada"
    />

    <moverAreaEncuestaDialog
      v-if="mostrarMoverArea"
      :mostrar="mostrarMoverArea"
      :areasEncuesta="areasEncuesta"
      :encuesta="encuestaParaModificar"
      @cancelar="cerrarMoverArea"
      @encuestaMovida="encuestaMovida"
    />

    <clonar-encuesta
      v-if="mostrarClonarcionEncuesta"
      :mostrar="mostrarClonarcionEncuesta"
      :encuesta="encuestaParaModificar"
      :areas="areasEncuesta"
      @cancelar="mostrarClonarcionEncuesta = false"
      @encuestaClonada="encuestaClonada"
    ></clonar-encuesta>

    <eliminacion-encuesta
      v-if="mostrarEliminacionEncuesta"
      :mostrar="mostrarEliminacionEncuesta"
      :idEncuesta="encuestaParaModificar._id"
      @cancelar="cerrarEliminacionEncuesta()"
      @encuestaEliminada="encuestaEliminada"
    ></eliminacion-encuesta>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

import { getAreasEncuesta, getEncuestasByArea } from "./encuestas.service";

import selectorAreas from "../widgets/selectorAreas.vue";
import crearAreaEncuestaDialog from "./crearAreaEncuestaDialog.vue";
import crearEncuestaDialog from "./crearEncuestaDialog.vue";
import moverAreaEncuestaDialog from "./moverAreaEncuestaDialog.vue";

export default {
  components: {
    "selector-areas": selectorAreas,
    crearAreaEncuestaDialog,
    crearEncuestaDialog,
    moverAreaEncuestaDialog,
    loadingEncuestas: () => import("./loadingEncuestas.vue"),
    "clonar-encuesta": () => import("./clonarEncuestaDialog.vue"),
    "eliminacion-encuesta": () => import("./confirmarEliminacionEncuesta.vue"),
  },

  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),

    esAdmin() {
      return (
        this.app === "campus" || this.role == "admin" || this.role === "master"
      );
    },

    encuestasFiltradas() {
      if (this.search)
        return this.encuestas.filter((e) =>
          e.nombre.toUpperCase().includes(this.search.toUpperCase())
        );
      return this.encuestas;
    },
  },

  watch: {
    sessionToken(value) {
      if (value) {
        this.getAreasEncuesta();
      }
    },
  },

  data: () => ({
    loading: true,
    search: "",
    areasEncuesta: [],
    areaSeleccionada: "",
    menuArea: [
      { text: "Agregar área", action: "agregarArea", icon: "mdi-plus" },
      { text: "Modificar área", action: "modificarArea", icon: "mdi-pencil" },
    ],
    mostrarCreacionArea: false,
    areaParaModificar: null,
    menuEncuesta: [
      {
        text: "Modificar encuesta",
        action: "modificarEncuesta",
        icon: "mdi-pencil",
      },
      {
        text: "Mover de área",
        action: "moverDeArea",
        icon: "mdi-subdirectory-arrow-left",
      },
      {
        text: "Duplicar encuesta",
        action: "clonarEncuesta",
        icon: "mdi-content-copy",
      },
    ],
    encuestas: [],
    mostrarCreacionEncuesta: false,
    encuestaParaModificar: null,
    mostrarMoverArea: false,
    mostrarClonarcionEncuesta: false,
    mostrarEliminacionEncuesta: false,
  }),

  created() {
    if (this.sessionToken) {
      this.getAreasEncuesta();
    }
  },

  methods: {
    menuAction(data) {
      switch (data.action) {
        case "agregarArea":
          this.mostrarCreacionArea = true;
          break;
        case "modificarArea":
          this.areaParaModificar = this.areasEncuesta.filter(
            (e) => e._id === this.areaSeleccionada
          )[0];
          this.mostrarCreacionArea = true;
          break;
        case "modificarEncuesta":
          this.encuestaParaModificar = this.encuestas.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarCreacionEncuesta = true;
          break;
        case "moverDeArea":
          this.encuestaParaModificar = this.encuestas.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarMoverArea = true;
          break;
        case "clonarEncuesta":
          this.encuestaParaModificar = this.encuestas.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarClonarcionEncuesta = true;
          break;
      }
    },

    getLocaleString(fecha) {
      const date = DateTime.fromISO(fecha)
        .setLocale("es")
        .toLocaleString(DateTime.DATE_FULL);
      return date;
    },

    async getAreasEncuesta() {
      this.loading = true;
      try {
        const serverResponse = await getAreasEncuesta();
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else if (serverResponse.areasEncuesta.length) {
          this.areasEncuesta = serverResponse.areasEncuesta;
          this.areaSeleccionada = serverResponse.areasEncuesta[0]._id;
          this.getEncuestas();
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    areaCreada(nuevaArea) {
      const areaExiste = this.areasEncuesta.filter(
        (e) => e._id === nuevaArea._id
      )[0];

      if (areaExiste) {
        const query = (element) => element._id === nuevaArea._id;
        const index = this.areasEncuesta.findIndex(query);
        this.areasEncuesta[index] = nuevaArea;
      } else this.areasEncuesta = [...this.areasEncuesta, nuevaArea];

      this.mostrarCreacionArea = false;
      this.areaParaModificar = null;

      this.areaSeleccionada = nuevaArea._id;
      this.getEncuestas();
    },

    agregarArea() {
      this.mostrarCreacionArea = true;
    },

    modificarArea() {
      this.areaParaModificar = this.areasEncuesta.filter(
        (e) => e._id === this.areaSeleccionada
      )[0];
      this.mostrarCreacionArea = true;
    },

    cerrarCreacionArea() {
      this.mostrarCreacionArea = false;
      this.areaParaModificar = null;
    },

    async getEncuestas() {
      this.loading = true;
      try {
        const serverResponse = await getEncuestasByArea(this.areaSeleccionada);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        this.encuestas = serverResponse.encuestas;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    abrirCreacionEncuesta() {
      this.mostrarCreacionEncuesta = true;
    },

    cerrarCreacionEncuesta() {
      this.mostrarCreacionEncuesta = false;
      this.encuestaParaModificar = null;
    },

    encuestaGuardada(encuesta) {
      const query = (e) => e._id === encuesta._id;
      const index = this.encuestas.findIndex(query);

      if (index !== -1) this.encuestas[index] = encuesta;
      else this.encuestas = [encuesta, ...this.encuestas];

      this.cerrarCreacionEncuesta();
    },

    cerrarMoverArea() {
      this.mostrarMoverArea = false;
      this.encuestaParaModificar = null;
    },

    encuestaMovida(encuesta) {
      this.cerrarMoverArea();
      this.encuestas = this.encuestas.filter((e) => e._id !== encuesta._id);
    },

    encuestaClonada(encuesta) {
      this.mostrarClonarcionEncuesta = false;
      this.encuestaParaModificar = null;

      const esMismaArea = encuesta.areaEncuesta === this.areaSeleccionada;
      if (esMismaArea) this.encuestas.push(encuesta);
    },

    mostrarEliminarEncuesta(encuesta) {
      this.encuestaParaModificar = encuesta;
      this.mostrarEliminacionEncuesta = true;
    },

    cerrarEliminacionEncuesta() {
      this.mostrarEliminacionEncuesta = false;
      this.encuestaParaModificar = null;
    },

    encuestaEliminada(encuesta) {
      this.cerrarEliminacionEncuesta();
      this.encuestas = this.encuestas.filter((e) => e._id !== encuesta._id);
    },
  },
};
</script>
